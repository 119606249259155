import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {
  DialogConfirmEvent,
  MsaDialogAction,
  MsaDialogComponent
} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {MsaRadiobuttonComponent} from '@shared/shared-module/components/msa-radiobutton/msa-radiobutton.component';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {UpdateEmails} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {
  CommunicationEmailsByType,
  PersonDataStateSelectors
} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {emailValidator} from '@shared/shared-module/validators/email.validator';
import {catchError, EMPTY, filter, map, Observable, of, switchMap, tap} from 'rxjs';
import {Language} from '../../../../../admin-query/src/app/core/api/generated/msa-duty-service';
import {
  EmailType,
  EmailUpdateDto,
  EmailVerifyDto,
  PersonRestService,
  VerifyResponseDto
} from '../../core/api/generated/msa-person-data';
import {EmailVerifyDialogComponent} from '../email-verify-dialog/email-verify-dialog.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, ReactiveFormsModule, MsaTextInputComponent, MsaRadiobuttonComponent],
  selector: 'msa-email-edit-dialog',
  standalone: true,
  templateUrl: './email-edit-dialog.component.html'
})
export class EmailEditDialogComponent implements OnInit {
  private store: Store = inject(Store);
  private personRestService: PersonRestService = inject(PersonRestService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private matDialog: MatDialog = inject(MatDialog);

  public language = toSignal(this.store.select(AppStateSelectors.slices.language), {
    initialValue: this.store.selectSnapshot(AppStateSelectors.slices.language)
  });

  title: TranslationString = 'i18n.person-data.dialogs.email-edit.title';

  emailForm = new FormGroup({
    privateEmail: new FormControl('', [emailValidator()]),
    businessEmail: new FormControl('', [emailValidator()]),
    otherEmail: new FormControl('', [emailValidator()]),
    preferredEmail: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.store
      .select(PersonDataStateSelectors.getCommunicationEmailsByType)
      .pipe(
        tap((emails: CommunicationEmailsByType) => this.populateEmailFields(emails)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    this.emailForm.controls.preferredEmail.valueChanges
      .pipe(
        filter(isDefined),
        tap((preferredEmail: string) => this.updateValidatorsOnPreferredChange(preferredEmail)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  populateEmailFields(emails: CommunicationEmailsByType) {
    this.emailForm.patchValue({
      privateEmail: emails.HOME.address,
      businessEmail: emails.BUSN.address,
      otherEmail: emails.OTHR.address,
      preferredEmail: emails.HOME.preferred
        ? 'privateEmailPreferred'
        : emails.BUSN.preferred
          ? 'businessEmailPreferred'
          : emails.OTHR.preferred
            ? 'otherEmailPreferred'
            : undefined
    });
  }

  updateValidatorsOnPreferredChange(preferredEmail: string) {
    const controls = this.emailForm.controls;
    controls.privateEmail.setValidators(emailValidator());
    controls.businessEmail.setValidators(emailValidator());
    controls.otherEmail.setValidators(emailValidator());
    if (preferredEmail === 'privateEmailPreferred') {
      controls.privateEmail.setValidators([Validators.required, emailValidator()]);
    } else if (preferredEmail === 'businessEmailPreferred') {
      controls.businessEmail.setValidators([Validators.required, emailValidator()]);
    } else if (preferredEmail === 'otherEmailPreferred') {
      controls.otherEmail.setValidators([Validators.required, emailValidator()]);
    }
    controls.privateEmail.updateValueAndValidity();
    controls.businessEmail.updateValueAndValidity();
    controls.otherEmail.updateValueAndValidity();
  }

  updateEmails($event: DialogConfirmEvent) {
    const privateEmailDto: EmailUpdateDto = {
      type: EmailType.Home,
      address: this.emailForm.value.privateEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'privateEmailPreferred'
    };
    const businessEmailDto: EmailUpdateDto = {
      type: EmailType.Busn,
      address: this.emailForm.value.businessEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'businessEmailPreferred'
    };
    const otherEmailDto: EmailUpdateDto = {
      type: EmailType.Othr,
      address: this.emailForm.value.otherEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'otherEmailPreferred'
    };
    let emails: Array<EmailUpdateDto> = [privateEmailDto, businessEmailDto, otherEmailDto];
    // Filter out empty emails
    emails = emails.filter(email => !(!email.address || email.address.length < 1));

    this.validatePreferredEmail(emails)
      .pipe(
        tap(isValidated => {
          if (isValidated) {
            $event.resolve();
            return true;
          } else {
            $event.reject();
            return EMPTY;
          }
        }),
        catchError(() => {
          $event.reject();
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  validatePreferredEmail(emails: EmailUpdateDto[]): Observable<boolean> {
    // Check if preferred email is set
    const emailToValidate = emails.find(x => x.preferred === true);
    if (!emailToValidate) {
      return of(false);
    }

    const emailVerifyDto: EmailVerifyDto = {
      address: emailToValidate.address ?? '',
      language: this.language() ?? Language.De
    };

    // Check if the following mail is already validated
    return this.personRestService.verifyPersonEmails(emailVerifyDto).pipe(
      switchMap((emailVerifyResponseDto: VerifyResponseDto) => {
        // If the mail is not yet validated open a verification Dialog
        if (emailVerifyResponseDto.alreadyVerified === false) {
          if (emailVerifyResponseDto.verificationCode === undefined) {
            throw new Error(`Undefined Verification Code!`);
          } else {
            return this.openDialog(emailVerifyDto, emailVerifyResponseDto.verificationCode).pipe(
              map(code => {
                if (code === '') return false;
                emailToValidate.verificationCode = code;
                emails = emails.map(email =>
                  email.address === emailToValidate.address && email.preferred === true ? emailToValidate : email
                );
                return this.store.dispatch(new UpdateEmails(emails));
              })
            );
          }

          // If the mail is already validated return true
        } else {
          emailToValidate.verificationCode = emailVerifyResponseDto.verificationCode;
          emails = emails.map(email =>
            email.address === emailToValidate.address && email.preferred === true ? emailToValidate : email
          );
          return this.store.dispatch(new UpdateEmails(emails));
        }
      })
    );
  }

  openDialog(emailVerifyDto: EmailVerifyDto, verificationHash: string): Observable<string> {
    const verifyDialog = this.matDialog.open(EmailVerifyDialogComponent, {
      data: {emailVerifyDto: emailVerifyDto, verificationHash: verificationHash}
    });
    return verifyDialog.beforeClosed().pipe(
      // Return if Verification was successful or cancelled
      map(result => {
        if (result === MsaDialogAction.CONFIRM) {
          return verifyDialog.componentInstance.emailVerifyForm.value.verificationCode ?? '';
        } else {
          return '';
        }
      })
    );
  }
}
